<template>
  <div>
    <div
      class="
        tw-p-5
        tw-py-10
        tw-text-5xl
        tw-text-center
        tw-bg-gray-100
        tw-text-gray-700
        tw-text-lg
      "
    >
      404
    </div>
    <div
      class="
        tw-p-5
        tw-text-gray-400
        tw-text-center
        tw-text-md
        tw-uppercase
        tw-font-normal
      "
    >
      Pagina não encontrada
    </div>
  </div>
</template>

<script>
export default {};
</script>
